// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #139BE1;
  --ion-color-primary-rgb: 19, 155, 225;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1188c6;
  --ion-color-primary-tint: #2ba5e4;

  /** secondary **/
  --ion-color-secondary: #0C6BD6;
  --ion-color-secondary-rgb: 12,107, 214;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0b5ebc;
  --ion-color-secondary-tint: #247ada;

  /** tertiary **/
  --ion-color-tertiary: #074087;
  --ion-color-tertiary-rgb: 7, 64, 135;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #063877;
  --ion-color-tertiary-tint: #205393;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #FC4D27;
  --ion-color-danger-rgb: 252, 77, 39;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #de4422;
  --ion-color-danger-tint: #fc5f3d;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** App CSS Variables **/
  // /!\ Prefix application CSS variables with "--app-" /!\

  /** white **/
  --app-white: #ffffff;
  --app-white2: #f1f1f1;

  /** greylight **/
  --app-greylight: #dadada;
  --app-greylight2: #cccccc;
  --app-greylight3: #eeeeee;

  /** grey **/
  --app-grey: #999999;

  /** greydark **/
  --app-greydark: #333333;
  --app-greydark2: #464646;
  --app-greydark3: #575757;

  /** black **/
  --app-black: #000000;

  /** blue **/
  --app-blue: #005499;

  /** bluelight **/
  --app-bluelight: #00ace8;
  --app-bluelight2: #0082de;

  /** red **/
  --app-red: #e50000;

  /** orange **/
  --app-orange: #ff6533;

  /** yellow **/
  --app-yellow: #fcb902;

  /** font **/
  --app-family1-regular: 'Roboto Regular', sans-serif;
  --app-family1-italic: 'Roboto Italic', sans-serif;
  --app-family1-light: 'Roboto Light', sans-serif;
  --app-family1-medium: 'Roboto Medium', sans-serif;
  --app-family1-bold: 'Roboto Bold', sans-serif;
  --app-family2-regular: 'Roboto Condensed Regular', sans-serif;
  --app-family2-light: 'Roboto Condensed Light', sans-serif;
  --app-family2-bold: 'Roboto Condensed Bold', sans-serif;
}

/** Components CSS Variables **/
ion-segment-button {
  --background-checked: var(--app-bluelight);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--app-greydark2);
  --border-radius: 0;
  --color: var(--app-greydark);
  --color-checked: var(--app-white);
  --indicator-color: transparent;
  --indicator-color-checked: var(--background-checked);
}

ion-select {
  --padding-top: 0;
  --padding-end: 8px;
  --padding-bottom: 0;
  --padding-start: 0;
}
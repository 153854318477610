// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Icônes de l'application
@import './theme/icons';

@font-face {
    font-family: "Roboto Regular";
    src: url("/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: "Roboto Italic";
    src: url("/assets/fonts/Roboto-Italic.ttf");
}
@font-face {
    font-family: "Roboto Medium";
    src: url("/assets/fonts/Roboto-Medium.ttf");
}
@font-face {
    font-family: "Roboto Light";
    src: url("/assets/fonts/Roboto-Light.ttf");
}
@font-face {
    font-family: "Roboto Bold";
    src: url("/assets/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: "Roboto Condensed Regular";
    src: url("/assets/fonts/RobotoCondensed-Regular.ttf");
}
@font-face {
    font-family: "Roboto Condensed Light";
    src: url("/assets/fonts/RobotoCondensed-Light.ttf");
}
@font-face {
    font-family: "Roboto Condensed Bold";
    src: url("/assets/fonts/RobotoCondensed-Bold.ttf");
}

/** Utils **/
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

/** Header **/
ion-header {
    box-shadow: 0px -7px 10px 10px rgba(#000, 0.5);

    ion-toolbar {
        --min-height: 56px;
        --background: var(--app-bluelight2);
        --color: var(--app-white);

        padding: 0 20px;
        z-index: 11;

        ion-title {
            padding-left: 0;
            font-size: 18px;
            text-align: left;
        }
    }

    ion-icon {
        width: 24px;
        height: 24px;
    }
}

/** Modal **/
.popup {
    position: fixed;
    top: 20vh;
    width: 90vw;
    left: 5vw;
    z-index: 10;
    background-color: white;
    padding: 2rem 2.5rem;
    u {
        color: var(--ion-color-primary);
    }
    .top-right {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    h4 {
        color: var(--ion-color-tertiary);
        font-size: 18px;
        font-family: "Roboto Condensed Bold";
        text-transform: uppercase;
        white-space: pre-line !important;
    }

}
#custom-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: black;
    opacity: 0.6;
    top: 0;
}
.vertical-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-modal {
    --max-height: calc(100vh - 10%);
    
    .modal-wrapper {
        overflow: auto;
        position: absolute;
        top: 5%;
        width: 90%;
        height: auto;
        opacity: 0;

        .ion-page {
            position: relative;
            contain: content;
        }
    }
}

/** Spinner **/
#pagination-spinner {
    display: flex;
    justify-content: center;
}

.form-padding-bottom
 {
    padding-bottom: 75px;
}

/** Styles globaux des formulaires **/
form, .form {
    ion-select {
        width: 100%;
        max-width: 100%;
        border-bottom: 1px solid var(--app-grey);
        color: var(--app-greydark2);
        font-family: var(--app-family1-regular);
        font-size: 1rem;
        text-transform: none;
    }

    ion-segment-button {
        min-height: 2.625rem;
        height: 2.625rem;
        @media (min-width: 769px) {
            max-width: none;            // No maximum width
        } 

        ion-label {
            margin: 0;
            font-size: .75rem !important;
            font-family: var(--app-family1-bold) !important;
        }
    }
}

ion-segment-button {
    &:hover {
        background-color: var(--app-bluelight);
        ion-label{
            color: var(--app-white) !important;
        }
    }
}
ion-button {
    &:hover {
        background-color: var(--app-bluelight);
        color: var(--app-white) !important;
    }
}

// Messages d'erreur
p.error {
    padding-top: 5px;
    font-family: var(--app-family2-light);
    font-size: 14px;
    color: var(--app-red);
    text-transform: uppercase;
}

.list-padding-left {
    padding-left: 17px;
}

app-home app-header-menu {
    position: absolute;
    top: 0;
    z-index: 100;
    right: -10px;
    ion-toolbar {
        --background: var(--app-bluelight2) !important;

        i.icon.icon-menu-verif {
            display: none;
        }
        i.icon.icon-menu.menu-points {
            display: block !important;
        }
    }

    .header-menu ion-item:last-of-type {
        --background: var(--ion-color-secondary);
    }
}

// Espacement pour les pages avec un ion-fab vertical=bottom
.fab-padding-bottom {
    padding-bottom: 86px;
}


.no-text-transform {
    text-transform: none !important;
}

.popover-content.sc-ion-popover-md {
    width: 94% !important;
    max-height: 300px;
}
.sc-ion-popover-md-h .item {
    padding-right: 10px;
}
.sc-ion-popover-md-h .item:last-child {
    --border-width: 0;
    --inner-border-width: 0;
}

h4 {
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: 18px !important;
}
ion-textarea {
    border: 1px solid #dadada;
    &[autosize] {
        border: 0px;
        border-bottom: 1px solid var(--app-grey);
    }
}
ion-input {
    border-bottom: 1px solid var(--app-grey);
}
.no-underline {
    width: 100%;
    white-space: nowrap;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: var(--color) !important;
}

// observation Footer 
.steps {
    background-color: var(--app-bluelight2);;
    padding: 15px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    span {
        background-color: white;
        width: 15px;
        height: 15px;
        margin-right: 20px;
        display: inline-block;
        border-radius: 50%;
        &.active {
            background-color: var(--app-yellow);
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.sub-header {
    --background: var(--app-greylight3);
    --color: var(--app-greydark2);
}
ion-button.btn-yes {
    --background: var(--ion-color-primary);
    --color: white;
}
ion-fab-button {
    --background: none;
    --box-shadow: none;

    width: 62px;
    height: 62px;
}
ion-item {
    // --highlight-color-invalid: transparent;
    --highlight-color-valid: transparent;
}
ion-badge {
    margin-right: 3px;
}
.overflow-change-line {
    white-space: initial !important;
}
h4 {
    white-space: initial !important;
}
.visual-disabled {
    color: #dddddd;
}
.no-padding-ion-input {
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
}
// ion-item disable 
.item-interactive-disabled.sc-ion-label-md-h, .item-interactive-disabled .sc-ion-label-md-h {
    opacity: 1 !important;
}
.visual-disabled {
    color: lightgray;
}
.invisible {
    background: transparent;
}
.required {
    color: red !important;
    margin-right: 5px;
    &.for-textarea {
        margin-top: -80px;
    }
}
.position-absolute {
    position: absolute;
}
.cursor-hand {
    cursor: pointer;
}
ion-select-popover {
    overflow: auto!important;
}
/* Required to open popover with new Chrome version */
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

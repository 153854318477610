i.icon {
    display: inline-block;
    &.icon-menu {
        background: url(/assets/imgs/icons/menu-sprite.png) no-repeat;
        background-size: auto 100%;
        height: 25px;
        &.menu-filter-check {
            background-position-x: 100%;
            width: 28px;
        }
        &.menu-filter {
            background-position-x: 80%;
            width: 22px;
        }
        &.menu-search {
            background-position-x: 62.5%;
            width: 22px;
        }
        &.menu-search-checked {
            background: url(/assets/imgs/icons/icon-search-checked.png) no-repeat;
            background-size: auto 100%;
            width: 28px;
        }
        &.menu-points {
            background-position-x: 48.5%;
            width: 10px;
        }
        &.menu-refresh {
            background-position-x: 33.5%;
            width: 22px;
            animation: rotateLoader 3s infinite linear;
        }
        &.menu-add {
            background-position-x: 16.5%;
            width: 22px;
        }
        &.menu-valid {
            background-position-x: 0%;
            width: 22px;
        }
    }
    &.icon-flag {
        background: url(/assets/imgs/icons/flags-icon.png) no-repeat;
        background-size: 100%;
        width: 20px;
        height: 13px;
        &.flag-fr {
            background-position: top center;
        }
        &.flag-en {
            background-position: bottom center;
        }
    }
    &.icon-export {
        background: url(/assets/imgs/icons/import-icon.png) no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
    }
    &.icon-export-big {
        background: url(/assets/imgs/icons/export-big-icon.png) no-repeat;
        background-size: contain;
        width: 61px;
        height: 61px;
    }
    &.icon-export {
        background: url(/assets/imgs/icons/export-icon.png) no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
    }
    &.icon-import {
        background: url(/assets/imgs/icons/import-icon.png) no-repeat;
        background-size: contain;
        width: 61px;
        height: 61px;
    }
    &.icon-statut {
        background: url(/assets/imgs/icons/statut-pictos-sprite.png) no-repeat;
        background-size: 100%;
        width: 16px;
        height: 16px;

        &.statut-valid {
            background-position-y: 0;
        }
        &.statut-valid-green {
            background-position-y: 29%;
        }
        &.statut-publish {
            background-position-y: 64%;
        }
        &.statut-wait {
            background-position-y: 100%;
        }
    }
    &.icon-deco {
        background: url(/assets/imgs/icons/deco-icon.png) no-repeat;
        background-size: contain;
        width: 11px;
        height: 11px;
    }
    &.icon-data-transfert {
        background: url(/assets/imgs/icons/data-transfert-pictos-sprite.png) no-repeat;
        background-size: 100%;
        width: 18px;
        height: 28px;

        &.data-import {
            background-position: top center;
        }
        &.data-export {
            background-position: bottom center;
        }
    }
    &.icon-menu-verif {
        width: 100%;
        height: 100%;
        &:before {
            background: url(/assets/imgs/icons/menu-verif-pictos-sprite.png) no-repeat;
            background-size: 350px;
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
        }
        &.menu-verif-home:before {
            background-position: 0 100%;
            width: 40px;
            height: 31px;
        }
        &.menu-verif-observations:before {
            background-position: 26% 100%;
            width: 14px;
            height: 36px;
        }
        &.menu-verif-bloc-notes:before {
            background-position: 49.8% 100%;
            width: 40px;
            height: 39px;
        }
        &.menu-verif-batiments:before {
            background-position: 75% 100%;
            width: 28px;
            height: 38px;
        }
        &.menu-verif-menu:before {
            background-position: 100% 88%;
            width: 20px;
            height: 28px;
        }
    }
    &.icon-verification {
        background: url(/assets/imgs/icons/verification-pictos-sprite.png) no-repeat;
        background-size: 100%;
        width: 22px;
        height: 18px;
        &.verification-num-affaire {
            background-position-y: 0;
        }
        &.verification-num-intervention {
            background-position-y: 8.9%;
        }
        &.verification-client {
            background-position-y: 17.7%;
        }
        &.verification-adresse {
            background-position-y: 26.5%;
        }
        &.verification-ref-site-client {
            background-position-y: 35.5%;
        }
        &.verification-agence {
            background-position-y: 44.2%;
        }
        &.verification-verificateur {
            background-position-y: 53.2%;
        }
        &.verification-planification {
            background-position-y: 62.5%;
        }
        &.verification-duree {
            background-position-y: 71.2%;
        }
        &.verification-accompagnateur {
            background-position-y: 81.5%;
        }
        &.verification-type-rapport {
            background-position-y: 91.3%;
        }
        &.verification-activite-principale {
            background-position-y: 100%;
        }
    }
    &.icon-sprite-menu {
        background: url(/assets/imgs/icons/menu-pictos-sprite.png) no-repeat;
        background-size: 100%;
        width: 22px;
        height: 20px;
        &.menu-entretien-prealable {
            background-position-y: 0;
        }
        &.menu-ref-reglementaire {
            background-position-y: 10%;
        }
        &.menu-alimentation {
            background-position-y: 21%;
        }
        &.menu-prise-terre {
            background-position-y: 33%;
        }
        &.menu-eclairage-securite {
            background-position-y: 44%;
        }
        &.menu-classement {
            background-position-y: 56%;
        }
        &.menu-q18 {
            background-position-y: 66.8%;
        }
        &.menu-corrige-visite {
            background-position-y: 100%;
        }
        &.menu-valide-visite {
            background-position-y: 78%;
        }
        &.menu-quitter {
            background-position-y: 89.5%;
        }
    }
    &.icon-new-visit {
        background: url(/assets/imgs/icons/new-visit-icon.png) no-repeat;
        background-size: contain;
        width: 62px;
        height: 62px;
    }
    &.icon-save {
        background: url(/assets/imgs/icons/save-icon.png) no-repeat;
        background-size: contain;
        width: 62px;
        height: 62px;
    }
    &.icon-materiel {
        background: url(/assets/imgs/icons/materiel-icon.png) no-repeat;
        background-size: contain;
        width: 18px;
        height: 29px;
    }
    &.icon-calendar {
        background: url(/assets/imgs/icons/calendrier-icon.png) no-repeat;
        background-size: contain;
        width: 30px;
        height: 31px;
    }
    &.icon-plus {
        background: url(/assets/imgs/icons/plus-minus-pictos.png) no-repeat left center;
        background-size: auto 100%;
        width: 30px;
        height: 30px;
    }
    &.icon-minus {
        background: url(/assets/imgs/icons/plus-minus-pictos.png) no-repeat right center;
        background-size: auto 100%;
        width: 30px;
        height: 30px;
    }
    &.icon-edit {
        background: url(/assets/imgs/icons/edit-icon.png) no-repeat;
        background-size: contain;
        width: 21px;
        height: 21px;
    }
    &.icon-edit-blue {
        background: url(/assets/imgs/icons/edit-blue-icon.png) no-repeat;
        background-size: contain;
        width: 21px;
        height: 21px;
    }
    &.icon-edit-white {
        background: url(/assets/imgs/icons/edit-white-icon.png) no-repeat;
        background-size: contain;
        width: 21px;
        height: 21px;
    }
    &.icon-add-white {
        background: url(/assets/imgs/icons/menu-sprite.png) no-repeat;
        background-size: auto 100%;
        height: 25px;
        background-position-x: 16.5%;
        width: 22px;
    }
    &.icon-delete {
        background: url(/assets/imgs/icons/delete-icon.png) no-repeat;
        background-size: contain;
        width: 22px;
        height: 26px;
    }
    &.icon-delete-white {
        background: url(/assets/imgs/icons/delete-icon-white.png) no-repeat;
        background-size: contain;
        width: 22px;
        height: 26px;
    }
    &.icon-menu-blue {
        background: url(/assets/imgs/icons/menu-blue-icon.png) no-repeat;
        background-size: contain;
        width: 4px;
        height: 16px;
    }
    &.icon-arrow {
        background: url(/assets/imgs/icons/arrow-icon.png) no-repeat;
        background-size: contain;
        width: 18px;
        height: 10px;
    }
    &.icon-camera {
        background: url(/assets/imgs/icons/camera-icon.png) no-repeat;
        background-size: contain;
        width: 30px;
        height: 24px;
    }
    &.icon-sprite-add-observation {
        background: url(/assets/imgs/icons/add-observation-sprite.png) no-repeat;
        background-size: 100%;
        width: 18px;
        height: 20px;
        &.add-observation-libelle {
            background-position-y: 0;
        }
        &.add-observation-q18 {
            background-position-y: 21%;
        }
        &.add-observation-invalid {
            background-position-y: 30%;
        }
        &.add-observation-valid {
            background-position-y: 39%;
        }
        &.add-observation-priorite {
            background-position-y: 50%;
        }
        &.add-observation-emplacement {
            background-position-y: 71%;
        }
        &.add-observation-continuite {
            background-position-y: 87%;
        }
        &.add-observation-preconisations {
            background-position: 3px 100.5%;
        }
    }
    &.icon-sprite-menu-recepteurs {
        background: url(/assets/imgs/icons/menu-recepteurs-sprite.png) no-repeat;
        background-size: 100%;
        width: 26px;
        height: 26px;
        &.menu-recepteurs-add {
            background-position-y: 0;
            height: 14px;
        }
        &.menu-recepteurs-list {
            background-position-y: 8.5%;
            height: 14px;
        }
        &.menu-recepteurs-mesure {
            background-position-y: 21.5%;
            height: 22px;
        }
        &.menu-recepteurs-add-top {
            background-position-y: 30.3%;
            height: 24px;
        }
        &.menu-recepteurs-add-bottom {
            background-position-y: 38.8%;
            height: 24px;
        }
        &.menu-recepteurs-copy-top {
            background-position-y: 46.8%;
            height: 28px;
        }
        &.menu-recepteurs-copy-bottom {
            background-position-y: 55%;
            height: 28px;
        }
        &.menu-recepteurs-delete {
            background-position-y: 63%;
            height: 20px;
            &.big-icon {
                height: 28px;
                width: 32px;
            }
        }
        &.menu-recepteurs-infinite {
            background-position-y: 70%;
            height: 12px;
        }
        &.menu-recepteurs-thunder {
            background-position-y: 75%;
            height: 20px;
        }
        &.menu-recepteurs-shield {
            background-position-y: 80.2%;
            height: 17px;
        }
        &.menu-recepteurs-frequency {
            background-position-y: 85%;
            height: 17px;
        }
        &.menu-recepteurs-sharp {
            background-position-y: 90.4%;
            height: 20px;
        }
        &.menu-recepteurs-ca-cc {
            background-position-y: 95.4%;
            height: 20px;
        }
        &.menu-recepteurs-voltage {
            background-position-y: 100%;
            height: 20px;
        }
    }
    &.icon-sprite-observation {
        background: url(/assets/imgs/icons/observations-sprite.png) no-repeat;
        background-size: 100%;
        width: 26px;
        height: 26px;
        &.observation-general {
            background-position-y: 0;
        }
        &.observation-cabinets {
            background-position-y: 47%;
        }
        &.observation-receptors {
            background-position-y: 100%;
            height: 27px;
        }
    }
    &.icon-loupe {
        background: url(/assets/imgs/icons/loupe-icon.png) no-repeat;
        background-size: contain;
        width: 60px;
        height: 60px;
    }
    &.icon-loader {
        background: url(/assets/imgs/icons/loader.png) no-repeat;
        background-size: contain;
        width: 61px;
        height: 61px;
        animation: rotateLoader 3s infinite linear;
    }
    &.icon-add {
        background: url(/assets/imgs/icons/new-visit-icon.png) no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
    }
    &.icon-sprite-security-lighting {
        background: url(/assets/imgs/icons/security-lighting-sprite.png) no-repeat;
        background-size: 100%;
        width: 26px;
        height: 26px;
        &.security-lighting-light {
            background-position-y: 0;
        }
        &.security-lighting-evacuation {
            background-position-y: 15%;
        }
        &.security-lighting-ambiance {
            background-position-y: 32%;
        }
        &.security-lighting-baes {
            background-position-y: 51%;
        }
        &.security-lighting-remote {
            background-position-y: 69%;
        }
        &.security-lighting-torch {
            background-position-y: 86%;
        }
        &.security-lighting-central-source {
            background-position-y: 100%;
        }
    }
    &.icon-info-white {
        background: url(/assets/imgs/icons/menu-verif-pictos-sprite.png) no-repeat;
        background-size: auto 100%;
        height: 25px;
        background-position-x: 16.5%;
        width: 22px;
    }
    &.icon-sprite-circuit {
        background: url(/assets/imgs/icons/circuit-sprite.png) no-repeat;
        background-size: 100%;
        width: 26px;
        height: 26px;
        &.circuit-label {
            background-position-y: 0;
        }
        &.circuit-type {
            background-position-y: 13%;
        }
        &.circuit-level {
            background-position-y: 26%;
        }
        &.circuit-pdc {
            background-position-y: 38%;
        }
        &.circuit-caliber {
            background-position-y: 51%;
        }
        &.circuit-section {
            background-position-y: 63%;
        }
        &.circuit-iz {
            background-position-y: 75%;
        }
        &.circuit-idn {
            background-position-y: 88%;
        }
        &.circuit-tempo {
            background-position-y: 101%;
        }
    }
    &.icon-unsatisfactory {
        background: url(/assets/imgs/icons/unsatisfactory-icon.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-unsatisfactory-white {
        background: url(/assets/imgs/icons/unsatisfactory-icon-white.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-satisfactory {
        background: url(/assets/imgs/icons/satisfactory-icon.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-satisfactory-white {
        background: url(/assets/imgs/icons/satisfactory-icon-white.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-not-verified {
        background: url(/assets/imgs/icons/not-verified-icon.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-not-verified-white {
        background: url(/assets/imgs/icons/not-verified-icon-white.png) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
    &.icon-check {
        background: url(/assets/imgs/check.png) no-repeat;
        background-size: contain;
        width: 29px;
        height: 24px;
    }
    &.icon-close {
        background: url(/assets/imgs/close.png) no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
    }
}

.div-menu {
    width: 50px;
    height: 40px;
    text-align: center;
    margin: 0!important;
    .icon-menu-blue {
        position: relative;
        top: 12px;
    }
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}